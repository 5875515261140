@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 255 255 255; /* hsl(0 0% 100%) */
    --foreground: 10 10 10; /* hsl(0 0% 3.9%) */

    --card: 255 255 255; /* hsl(0 0% 100%) */
    --card-foreground: 10 10 10; /* hsl(0 0% 3.9%) */

    --popover: 255 255 255; /* hsl(0 0% 100%) */
    --popover-foreground: 10 10 10; /* hsl(0 0% 3.9%) */

    --primary: 23 23 23; /* hsl(0 0% 9%) */
    --primary-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --secondary: 245 245 245; /* hsl(0 0% 96.1%) */
    --secondary-foreground: 23 23 23; /* hsl(0 0% 9%) */

    --muted: 245 245 245; /* hsl(0 0% 96.1%) */
    --muted-foreground: 115 115 115; /* hsl(0 0% 45.1%) */

    --accent: 245 245 245; /* hsl(0 0% 96.1%) */
    --accent-foreground: 23 23 23; /* hsl(0 0% 9%) */

    --destructive: 153 25 25; /* hsl(0 84.2% 60.2%) */
    --destructive-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --border: 229 229 229; /* hsl(0 0% 89.8%) */
    --input: 229 229 229; /* hsl(0 0% 89.8%) */
    --ring: 10 10 10; /* hsl(0 0% 3.9%) */

    --radius: 0.5rem;
  }

  .dark {
    --background: 10 10 10; /* hsl(0 0% 3.9%) */
    --background-z-10: 15 15 15;
    --background-z-20: 20 20 20;
    --background-z-30: 25 25 25;
    --background-z-40: 30 30 30;
    --foreground: 250 250 250; /* hsl(0 0% 98%) */

    --card: 10 10 10; /* hsl(0 0% 3.9%) */
    --card-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --popover: 10 10 10; /* hsl(0 0% 3.9%) */
    --popover-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --primary: 250 250 250; /* hsl(0 0% 98%) */
    --primary-foreground: 23 23 23; /* hsl(0 0% 9%) */

    --secondary: 38 38 38; /* hsl(0 0% 14.9%) */
    --secondary-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --muted: 38 38 38; /* hsl(0 0% 14.9%) */
    --muted-foreground: 163 163 163; /* hsl(0 0% 63.9%) */

    --accent: 38 38 38; /* hsl(0 0% 14.9%) */
    --accent-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --destructive: 78 29 29; /* hsl(0 62.8% 30.6%) */
    --destructive-foreground: 250 250 250; /* hsl(0 0% 98%) */

    --border: 38 38 38; /* hsl(0 0% 14.9%) */
    --input: 38 38 38; /* hsl(0 0% 14.9%) */
    --ring: 212 212 212; /* hsl(0 0% 83.1%) */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@font-face {
  font-family: "Geist";
  src: url("./fonts/GeistVF.woff") format("woff");
}

.font-geist {
  font-family: "Geist", Arial, Helvetica, sans-serif;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  @apply font-geist;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

.home-ball {
  animation: home-ball-animation 12000ms ease infinite;
}

@keyframes home-ball-animation {
  0% {
    scale: 0;
    opacity: 0;
  }
  40%,
  60% {
    scale: 1;
    opacity: 1;
  }
  100% {
    scale: 0;
    opacity: 0;
  }
}

.animate-appear {
  animation: appear 1000ms ease 1;
  animation-fill-mode: forwards;
  transform-origin: top;
}

@keyframes appear {
  0% {
    scale: 0.9;
    opacity: 0;
    filter: blur(20px);
  }
  100% {
    scale: 1;
    opacity: 1;
    filter: blur(0);
  }
}
